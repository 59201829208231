.tooltip {
  background-color: hsl(var(--foreground-hsl));
  border-radius: 0.25rem;
  box-shadow: 0 0.125rem 0.5rem hsla(0, 0%, 0%, 0.5);
  color: hsl(var(--background-hsl));
  font-size: 0.875rem;
  font-weight: bold;
  max-width: 12rem;
  padding: 0.125rem 0.5rem;
  text-align: center;
  width: max-content;
  z-index: 10;
  transition: none 0.25s ease-in-out;
  transition-property: opacity, visibility, margin;
}

.tooltip:not(.active) {
  opacity: 0;
  pointer-events: none;
  visibility: hidden;
}

.tooltip.active {
  margin-top: 0.375rem;
}
