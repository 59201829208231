.ships {
  display: flex;
  overflow-x: auto;
}

.ships > * {
  flex: 0 0 auto;
}

.ships > * + * {
  margin-left: 0.375rem;
}

.ships {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.ships::-webkit-scrollbar {
  display: none;
}
