.menu {
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 1.5rem;
}

.menu ul {
  align-items: center;
  display: flex;
  flex-grow: 1;
  justify-content: space-between;
  margin-left: 3rem;
}

.menu li + li {
  margin-left: 1.5rem;
}

.menu li {
  font-weight: bold;
}
