html {
  --foreground-hsl: 200, 12%, 86%;
  --background-hsl: 200, 6%, 10%;

  background-color: hsl(var(--background-hsl));
  color: hsl(var(--foreground-hsl));
}

#root {
  min-width: 1024px;
  height: 100vh;
  display: flex;
  flex-direction: column;
}

button {
  cursor: pointer;
}

[disabled] {
  opacity: 0.5;
}
