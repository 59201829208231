.table {
  table-layout: fixed;
  width: 100%;
}

.table th {
  font-weight: bold;
}

.table th button {
  cursor: pointer;
  display: inline-block;
  width: 100%;
  text-align: inherit;
}

.table th:nth-child(5),
.table td:nth-child(5),
.table th:nth-child(6),
.table td:nth-child(6) {
  text-align: center;
}

.table th:nth-child(7),
.table td:nth-child(7) {
  text-align: right;
}

.table th:nth-child(n + 5),
.table td:nth-child(n + 5) {
  width: 4.5rem;
}

.table td,
.table th {
  padding: 0.375rem;
}

.table tr:nth-child(2n + 1) td {
  background-color: hsla(var(--foreground-hsl), 0.05);
}
