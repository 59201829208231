.summary {
}

.summary h1 {
  padding: 0.375rem;
}

.summary h1 span {
  font-size: 1.5em;
  margin-right: 0.375rem;
}

.summary ul {
  background-color: hsla(var(--foreground-hsl), 0.05);
  padding: 0.1875rem;
  display: flex;
  flex-wrap: wrap;
}

.summary li {
  margin: 0.1875rem;
}
