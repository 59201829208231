.entity {
  align-items: center;
  display: flex;
  max-width: 100%;
}

.entity .image {
  border-radius: 0.125rem;
  background-color: hsla(0, 0%, 0%, 0.5);
  display: block;
  height: 2em;
  image-rendering: crisp-edges;
  width: 2em;
}

.entity .image + .text {
  margin-left: 0.375rem;
}

.entity .text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
