.drawer {
  background-color: hsl(var(--foreground-hsl));
  bottom: 0;
  color: hsl(var(--background-hsl));
  position: fixed;
  top: 0;
  transform: translateX(0);
  transition: transform 0.25s ease-in-out;
  width: 24rem;
  display: flex;
  flex-direction: column;
}

.drawer.left {
  left: 0;
}

.drawer.right {
  right: 0;
}

.drawer.left:not(.active) {
  transform: translateX(-100%);
}

.drawer.right:not(.active) {
  transform: translateX(100%);
}

.header {
  align-items: stretch;
  display: flex;
  justify-content: space-between;
  /* box-shadow: 0 1px hsla(var(--background-hsl), 0.125); */
}

.header h1 {
  font-weight: bold;
  padding: 1.5rem;
}

.header button {
  font-size: 2.5em;
  line-height: 0;
  padding: 1.375rem 1.5rem 1.625rem;
}

.content {
  overflow-y: auto;
  padding: 1.5rem;
  scrollbar-color: hsla(var(--background-hsl), 0.5) transparent;
  scrollbar-width: thin;
}

.content::-webkit-scrollbar-thumb {
  background-color: hsla(var(--background-hsl), 0.5);
}

.content::-webkit-scrollbar-track {
  background-color: transparent;
}

.content::-webkit-scrollbar-corner {
  background-color: transparent;
}

.content::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

.content > * + * {
  margin-top: 3rem;
}
